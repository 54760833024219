import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Nice shout-out for PayPerks in the Association of Government Accountants' recent report on the cost savings generated from transitioning from checks to prepaid cards:`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`“We’re now beginning to see a focus on using the prepaid platform to drive financial capability for cardholders, as in the recent announcement of the rollout of PayPerks in the Treasury program,” `}{`[Andrew Gillen of MasterCard]`}{` said. “It will be interesting to see if state governments follow suit.”`}</em></p>
    </blockquote>
    <p>{`In addition to ease of use with prepaid cards, the AGA found significant savings for both the payees and the recipients of federal benefits payments. While it's possible to use the government prepaid programs at no cost, an average cardholder will see a cost of .03% of the payments received on a prepaid card-- compared to costs up to 4% for the alternative in check cashing fees.`}</p>
    <p>{`The government is also saving-- Treasurer of the United States Rosio Rios famously wrote a check to American taxpayers in 2011 for the \\$1 billion that will be saved by moving from checks to prepaid cards.`}</p>
    <p>{`It's a win-win-win!`}</p>
    <p>{`Read the full article here: `}<a parentName="p" {...{
        "href": "http://bankcreditnews.com/news/aga-report-finds-government-prepaid-cards-offer-numerous-advantages/11472/"
      }}>{`http://bankcreditnews.com/news/aga-report-finds-government-prepaid-cards-offer-numerous-advantages/11472/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      